import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import config from "../../config";
import Recaptcha from "react-google-recaptcha";
//redux:
import { connect } from "react-redux";
//containers:
// import Container from "../Container";
//components:
import Input from "../../components/input/Input";
// import SocialButton from "../../components/socialButton/SocialButton";
// import Lottie from "../../components/lottie/Lottie";
//classes:
import { lang } from "../../classes/lang";
import { register, login } from "../../classes/player";
import { go } from "../../classes/routesLazy";
import { getSetParams, getStorageParams, isServer, removeNulls } from "../../classes/utills";
//redux:
import { storeSet } from "../..";
//icons:
import Icon from "react-icons-kit";
import { signIn } from "react-icons-kit/fa/signIn";
import { eye } from "react-icons-kit/icomoon/eye";
import { eyeBlocked } from "react-icons-kit/icomoon/eyeBlocked";
import ImageCached from "../../classes/cache";
// import { facebook } from "react-icons-kit/fa/facebook";
// import { google } from "react-icons-kit/fa/google";
// import { instagram } from "react-icons-kit/fa/instagram";

class Registration extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			//registration:
			regUserName: "",
			regPromoCode: "",
			//login:
			userName: "",
			password: "",
			_2fa: "",
			recaptchaAction: "",
			mode: 0,
			pass0_eye: false,
		};
	}
	static get propTypes() {
		return {
			match: PropTypes.object,
			lang: PropTypes.string,
			uid: PropTypes.number,
		};
	}

	componentDidMount = () => {
		getSetParams(this.props.match?.params);
	};
	componentDidUpdate = (prevProps) => {
		if (prevProps.uid !== this.props.uid && this.props.uid !== -1) {
			storeSet({
				modal: {
					$set: null,
				},
			});
		}
	};
	recaptchaRun = () => {
		this.recaptcha.reset();
		this.recaptcha.execute();
	};
	onRecaptchaChange = async (recaptcha) => {
		if (this.state.recaptchaAction === "") return;
		let data = {};
		if (this.state.recaptchaAction === "register") {
			const storageParams = removeNulls(getStorageParams());
			const o = {
				recaptcha,
				un: this.state.regUserName,
				promocode: this.state.regPromoCode === "" ? undefined : this.state.regPromoCode,
				...storageParams,
			};
			data = await register(o);
			try {
				if (!data.error) window.esk("track", "Conversion");
			} catch (err) {
				console.error(err);
			}
		} else if (this.state.recaptchaAction === "login") {
			data = await login({
				recaptcha,
				un: this.state.userName,
				pw: this.state.password,
				token: this.state._2fa,
			});
		}
		await this.setState({ loading: false, recaptchaAction: "" });
		if (!data.error) go("home");
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	loginClick = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		await this.setState({ loading: true, recaptchaAction: "login" });
		this.recaptchaRun();
	};
	registerClick = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		await this.setState({ loading: true, recaptchaAction: "register" });
		this.recaptchaRun();
	};
	render() {
		const disabled = this.state.loading;
		return (
			// <Container>
			<div className="registration">
				<Recaptcha
					ref={(el) => (this.recaptcha = el)}
					size="invisible"
					sitekey={config.app.recaptcha}
					onChange={this.onRecaptchaChange}
					badge="inline"
					theme="dark"
				/>
				<div className="regLogo">
					<ImageCached storageName="ui" title="logo" width={160} height={40} url={"/assets/dark_logo3.png"} />
				</div>
				{!isServer() && (
					<div className="anim">
						{/* <Lottie
							ref={(el) => (this.anim = el)}
							src={"/assets/ae/sign.json"}
							style={{
								height: "100px",
								pointerEvents: "none",
							}}
						/> */}
						<ImageCached storageName="ui" title="registration" width={90} height={91} url={"/assets/reg_90x91.png"} />
					</div>
				)}
				<div className="header">
					<button
						id="btnTabJoin"
						onClick={(e) => [e.stopPropagation(), this.setState({ mode: 0 })]}
						className={`btnDefault success btnHeader1 ${this.state.mode === 0 ? "selected" : ""}`}
						onKeyDown={(e) => {
							if (e.key === "ArrowRight") {
								e.preventDefault();
								document.getElementById("btnTabLogin").click();
								document.getElementById("btnTabLogin").focus();
							} else if (e.key === "ArrowDown" || e.key === "Tab") {
								e.preventDefault();
								document.getElementById("iRegistration").focus();
							} else if (e.key === "ArrowUp") {
								document.querySelector(".modalClose").focus();
							}
						}}
					>
						{lang.translate("create_an_account")}
					</button>
					<button
						id="btnTabLogin"
						onClick={(e) => [e.stopPropagation(), this.setState({ mode: 1 })]}
						className={`btnDefault success btnHeader2 ${this.state.mode === 1 ? "selected" : ""}`}
						onKeyDown={(e) => {
							if (e.key === "ArrowLeft") {
								e.preventDefault();
								document.getElementById("btnTabJoin").click();
								document.getElementById("btnTabJoin").focus();
							} else if (e.key === "ArrowDown" || e.key === "Tab") {
								e.preventDefault();
								document.getElementById("iLogin").focus();
							} else if (e.key === "ArrowUp") {
								document.querySelector(".modalClose").focus();
							}
						}}
					>
						{lang.translate("sign_in")}
					</button>
				</div>
				<div className="sections">
					<div className={`tape c${this.state.mode}`}>
						<form className="sRegistration">
							<div className="block">
								<Input
									id="iRegistration"
									disabled={disabled}
									className="m10_b"
									name="regUserName"
									placeholder={`${lang.translate("user")} (6-12)`}
									value={this.state.regUserName}
									onChange={this.onChange}
									validation="userName"
									onEnter={this.registerClick}
									autoFocus={this.state.mode === 0}
									onDown={() => [document.getElementById("iPromoCode").focus()]}
									onUp={() => document.getElementById("btnTabJoin").focus()}
								/>
							</div>
							<div className="block">
								<Input
									id="iPromoCode"
									disabled={disabled}
									className="m10_b"
									name="regPromoCode"
									placeholder={`${lang.translate("promo_code")} (${lang.translate("optional")})`}
									value={this.state.regPromoCode}
									onChange={this.onChange}
									validation="salt"
									onEnter={this.registerClick}
									onUp={() => document.getElementById("iRegistration").focus()}
									onDown={() => [document.getElementById("btnJoin").focus()]}
								/>
							</div>
							<div className="block">
								<div className="terms m10_b">
									{`${lang.translate("by_clicking")} `}
									<a href={config.links.terms} rel="noopener noreferrer">
										{`${lang.translate("tc")} & ${lang.translate("privacy")}`}
									</a>
									{` ${lang.translate("gambling_forbidden")}`}
								</div>
							</div>
							<div className="block">
								<button
									id="btnJoin"
									disabled={disabled || this.state.regUserName.length < 6}
									onClick={this.registerClick}
									className={"btnDefault success m10_b"}
									onKeyDown={(e) => {
										if (e.key === "ArrowUp" || e.key === "Tab") {
											e.preventDefault();
											document.getElementById("iPromoCode").focus();
										}
									}}
								>
									{lang.translate("join")}
								</button>
							</div>
						</form>

						{/* <div className="block">
					<label>{lang.translate("or")}</label>
				</div> */}

						<form className="sLogin">
							<div className="block">
								<Input
									id="iLogin"
									disabled={disabled}
									className="m10_b"
									autoComplete="on"
									name="userName"
									maxLength={12}
									placeholder={`${lang.translate("user")} (6-12)`}
									value={this.state.userName}
									onChange={this.onChange}
									validation="userName"
									onEnter={this.loginClick}
									lastpass={true}
									// tabIndex={1}
									autoFocus={false}
									onUp={() => document.getElementById("btnTabLogin").focus()}
									onDown={() => [document.getElementById("iPass").focus()]}
								/>
							</div>
							<div className="block">
								<button
									onClick={(e) => [e.stopPropagation(), e.preventDefault(), this.setState({ pass0_eye: !this.state.pass0_eye })]}
									className={`btnIcon eye ${this.state.pass0_eye ? "on" : "off"}`}
								>
									<Icon size={14} icon={this.state["pass0_eye"] ? eye : eyeBlocked} />
								</button>
								<Input
									id="iPass"
									disabled={disabled}
									className="m10_b"
									name="password"
									autoComplete="on"
									type={this.state["pass0_eye"] ? "text" : "password"}
									placeholder={`${lang.translate("password")} (6-20)`}
									value={this.state.password}
									onChange={this.onChange}
									validation="password"
									onEnter={this.loginClick}
									lastpass={true}
									// tabIndex={2}
									onUp={() => [document.getElementById("iLogin").focus()]}
									onDown={() => [document.getElementById("i2fa").focus()]}
								/>
							</div>
							<div className="block">
								<Input
									id="i2fa"
									disabled={disabled}
									className="m10_b"
									name="_2fa"
									placeholder={lang.translate("fa_if_enabled")}
									value={this.state._2fa}
									onChange={this.onChange}
									validation="2fa"
									onEnter={this.loginClick}
									// tabIndex={3}
									onUp={() => [document.getElementById("iPass").focus()]}
									onDown={() => {
										const btn = document.getElementById("btnLogin");
										if (btn.disabled) {
											document.getElementById("btnForgot").focus();
										} else {
											btn.focus();
										}
									}}
								/>
							</div>
							<div className="block">
								<button
									id="btnLogin"
									disabled={disabled || this.state.userName.length < 6 || this.state.password.length < 6}
									onClick={this.loginClick}
									className={"btnDefault success m10_b"}
									// tabIndex={4}
									onKeyDown={(e) => {
										if (e.key === "ArrowUp") {
											document.getElementById("i2fa").focus();
										} else if (e.key === "ArrowDown" || e.key === "Tab") {
											e.preventDefault();
											document.getElementById("btnForgot").focus();
										}
									}}
								>
									<Icon icon={signIn} />
									<label className="m5_l">{lang.translate("login")}</label>
								</button>
							</div>
							{/* <div className="block">
                  <div className="socialButtons">
                    <SocialButton
                      provider="facebook"
                      appId="YOUR_APP_ID"
                      onLoginSuccess={res => console.log("success", res)}
                      onLoginFailure={res => console.log("failure", res)}
                    >
                      <Icon icon={facebook} />
                    </SocialButton>
                    <SocialButton
                      provider="google"
                      appId="YOUR_APP_ID"
                      onLoginSuccess={res => console.log("success", res)}
                      onLoginFailure={res => console.log("failure", res)}
                    >
                      <Icon icon={google} />
                    </SocialButton>
                    <SocialButton
                      provider="instagram"
                      appId="YOUR_APP_ID"
                      onLoginSuccess={res => console.log("success", res)}
                      onLoginFailure={res => console.log("failure", res)}
                    >
                      <Icon icon={instagram} />
                    </SocialButton>
                  </div>
                </div> */}
							<div className="block">
								<button
									id="btnForgot"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										storeSet({ modal: { $set: { name: "forgotpass", canClose: true, props: {} } } });
									}}
									disabled={disabled}
									className={"btnLink m10_b"}
									onKeyDown={(e) => {
										e.preventDefault();
										e.stopPropagation();
										if (e.key === "ArrowUp") {
											const btn = document.getElementById("btnLogin");
											if (btn.disabled) {
												document.getElementById("i2fa").focus();
											} else {
												btn.focus();
											}
										} else if (e.key === "Tab") {
											document.getElementById("iLogin").focus();
										} else if (e.key === "Enter") {
											storeSet({ modal: { $set: { name: "forgotpass", canClose: true, props: {} } } });
										}
									}}
								>
									<label>{`${lang.translate("forgot_password")} ?`}</label>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			// </Container>
		);
	}
}
Registration.onKeyDown = (e) => {
	if (["Tab", "ArrowDown"].includes(e.key)) {
		e.preventDefault();
		const btnTabLogin = document.getElementById("btnTabLogin");
		const btnTabJoin = document.getElementById("btnTabJoin");
		if (btnTabLogin.classList.contains("selected")) {
			btnTabLogin.focus();
		} else {
			btnTabJoin.focus();
		}
	}
};
const a = (store) => {
	return {
		lang: store.lang.key,
		uid: store.player.uid,
	};
};

export default connect(a, null, null, { forwardRef: true })(Registration);
