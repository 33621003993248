import React, { forwardRef } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import lang from "../../classes/lang";
import Form from "../../components/form/Form";
import { useSlotTemplate } from "./useBattle";
import Axios from "../../classes/axios";
import { onResult, closeModal } from "../../classes/utills";
import toast from "../../classes/toast";
// import PropTypes from "prop-types";
//containers:
// import Container from "../Container";

// eslint-disable-next-line no-unused-vars
const BattleCreate = forwardRef((props, ref) => {
	BattleCreate.propTypes = {
		// uid: PropTypes.any.isRequired,
	};
	const [loading, setLoading] = useState(false);
	const frmRef = useRef();
	const [amountUsd, setAmountUsd] = useState();
	const [participants, setParticipants] = useState(5);
	const [waitMs, setWaitMs] = useState(60000 * 3);
	const [templateId, setTemplateId] = useState();
	const [gamePattern, setGamePattern] = useState();
	const { templates } = useSlotTemplate({ amountUsd });
	const short = useSelector((s) => s.coin?.selected);
	const frmData = useMemo(() => {
		const arr = [];
		arr.push({
			type: "select",
			label: lang.translate("amount") + "$",
			name: "amountUsd",
			value: amountUsd,
			data: [0.2, 1, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 50, 100, 200, 400, 500, 1000, 5000, 10000].map((el) => ({
				id: `${el}`,
				value: el,
				title: `${el}`,
			})),
			onSelect: (item) => {
				setAmountUsd(item.value);
				setTemplateId();
			},
		});
		arr.push({
			type: "select",
			label: lang.translate("participants"),
			name: "participants",
			value: participants,
			data: [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 100, 200].map((el) => ({
				id: `${el}`,
				value: el,
				title: `${el}`,
			})),
			onSelect: (item) => {
				setParticipants(item.value);
			},
		});
		arr.push({
			type: "select",
			label: lang.translate("waiting_time"),
			name: "waitMs",
			value: waitMs,
			data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30]
				.map((min) => {
					const ms = min * 60000;
					return {
						id: `${ms}`,
						value: ms,
						title: `${min} ${lang.translate("min")}`,
					};
				})
				.concat(
					[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((h) => {
						const ms = h * 60 * 60000;
						return {
							id: `${ms}`,
							value: ms,
							title: `${h} ${lang.translate("h")}`,
						};
					})
				),
			onSelect: (item) => {
				setWaitMs(item.value);
			},
		});
		arr.push({
			type: "select",
			label: lang.translate("game"),
			name: "templateId",
			className: "gameSelect",
			value: templateId,
			data: (templates ?? [])
				.filter((el) => el.name.toLowerCase().includes(gamePattern))
				.map((el) => ({
					id: `${el._id}`,
					value: el._id,
					title: `${el.name}`,
					c: <img src={el.image} alt="" />,
				})),
			onSearch: (pattern) => {
				setGamePattern(`${pattern ?? ""}`.toLowerCase());
			},
			onSelect: (item) => {
				setTemplateId(item.value);
			},
		});
		return arr;
	}, [templates, amountUsd, participants, waitMs, templateId, gamePattern]);

	const onCreate = useCallback(async () => {
		if (loading) return;
		const { amountUsd, participants, templateId, waitMs } = frmRef.current.getValues();
		if (typeof templateId === "undefined") return;
		setLoading(true);
		const o = { short, amountUsd, maxParticipants: participants, templateId, waitMs };
		const result = await Axios.post("api/battle/create", o);
		await onResult(result, "unexpected_error", () => {
			toast.success(lang.translate("battle_created"));
			closeModal();
		});
		setLoading(false);
	}, [short, loading]);

	return (
		<div className="BattleCreate">
			<h2>{lang.translate("create_new_battle")}</h2>
			{Array.isArray(frmData) && (
				<Form
					key={`frm_${amountUsd}`}
					ref={frmRef}
					className="frmBattleCreate"
					data={frmData}
					buttons={[
						{
							img: "/assets/icons2/svg/battle.svg",
							label: "create",
							disabled: !templateId,
							className: "diceBtn green",
							onClick: onCreate,
						},
					]}
				/>
			)}
		</div>
	);
});
BattleCreate.displayName = "BattleCreate";
export default BattleCreate;
